.ui.header.total-price{
  font-weight: 400;
}
.ui.header.total-price span {
  color: #04a260;
  font-weight: 600;
}

.helper-text{
  font-size: 0.8em;
  color: #777;
}
.semantic-table-custom-wrapper{
  overflow-x: auto;
}
.content p{
  white-space: pre-line;
}